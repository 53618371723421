<template>
  <v-container>
    <v-container>
      <v-row>
        <v-col>
          <v-select
              :items="currencies"
              v-model="selectedCurrency"
              label="Currency"
          ></v-select>
        </v-col>
<!--        <v-col>-->
<!--          <v-select-->
<!--              :items="gameTypes"-->
<!--              v-model="selectedGameType"-->
<!--              label="Traffic"-->
<!--          ></v-select>-->
<!--        </v-col>-->
        <v-col>
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="datesRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="datesDisplayRange"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="datesRange"
                range
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(datesRange)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="text-align: left">
            userId
          </th>
          <th class="text-left" style="text-align: left">
            nickname
          </th>
          <th class="text-left" style="text-align: left; padding-left: 1.7rem">
            Total bets
          </th>
          <th class="text-left" style="text-align: left; padding-left: 1.7rem">
            Total wins
          </th>
          <th class="text-left" style="text-align: left; padding-left: 1.7rem">
            GGR from
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="player in topPlayers" :key="player.userId">
          <td>{{ player.userId }}</td>
          <td>{{ player.nickname }}</td>
          <td>{{ player.bets_sum }}</td>
          <td>{{ player.results_sum }}</td>
          <td>{{ player.ngr_sum }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import * as axios from "axios";

export default {
  name: 'MainTopPlayers',
  data() {
    return {
      currencies: ["USD", "INR", "RUB"],
      selectedCurrency: "USD",
      topPlayers: [],
      currency_to_stat: {},
      datesRange: [],
      menu: false,
      gameTypes: ['all', 'signals', 'organic'],
      selectedGameType: 'all'
    };
  },
  mounted () {
    const dateNow = (new Date()).toISOString().split("T")[0];
    this.datesRange = [dateNow, dateNow];

    this.loadCurrencies();
  },
  watch:{
    selectedCurrency: function () {
      this.loadOverallStats();
    },
    datesDisplayRange: function () {
      this.loadOverallStats();

      this.loadCurrencies();
    },
    selectedGameType: function () {
      this.loadOverallStats();
    },
  },
  computed: {
    datesDisplayRange() {
      return this.datesRange.join(' ~ ');
    },
    datesRequestRange() {
      const startingAtString = this.datesRange[0];
      const startingAtDate = new Date(startingAtString);

      const endingAtString = this.datesRange[1];
      const endingAtDate = new Date();
      endingAtDate.setDate((new Date(endingAtString)).getDate() + 1);
      return [startingAtDate.toISOString(), endingAtDate.toISOString()];
    },
  },
  methods: {
    loadCurrencies: async function () {
      const res = await axios.get(
          process.env.VUE_APP_API_URL + "/getCurrencies",
          {
            params: { startingAt: this.datesRequestRange[0], endingAt: this.datesRequestRange[1], gameType: this.selectedGameType, currency: this.selectedCurrency },
            headers: {
              "authToken": localStorage.getItem("authToken")
            }
          }
      );

      this.currencies = res.data;
    },
    loadOverallStats: async function () {
      const res = await axios.get(
          process.env.VUE_APP_API_URL + "/getTopPlayers",
          {
            params: { startingAt: this.datesRequestRange[0], endingAt: this.datesRequestRange[1], gameType: this.selectedGameType, currency: this.selectedCurrency },
            headers: {
              "authToken": localStorage.getItem("authToken")
            }
          }
      );

      this.currency_to_stat = res.data;

      await this.loadStatForCurrency();
    },
    loadStatForCurrency: async function () {
      let players = [];

      const currency_stat = this.currency_to_stat[this.selectedCurrency];

      for (let index in currency_stat) {
        const player_info = currency_stat[index];
        players.push({
          userId: player_info.userId,
          nickname: player_info.nickname,
          bets_sum: new Intl.NumberFormat(undefined, {maximumFractionDigits: 8}).format(player_info.bets_sum),
          results_sum: new Intl.NumberFormat(undefined, {maximumFractionDigits: 8}).format(player_info.results_sum),
          ngr_sum: new Intl.NumberFormat(undefined, {maximumFractionDigits: 8}).format(player_info.bets_sum - player_info.results_sum)
        });
      }

      this.topPlayers = players;
    }
  }
}
</script>
