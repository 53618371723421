<template>
  <v-container>
    <v-container>
      <v-row>
        <v-col>
          <v-select
              :items="currencies"
              v-model="selectedCurrency"
              label="Currency"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
              :items="games"
              v-model="selectedGame"
              label="Game"
          ></v-select>
        </v-col>
<!--        <v-col>-->
<!--          <v-select-->
<!--              :items="gameTypes"-->
<!--              v-model="selectedGameType"-->
<!--              label="Traffic"-->
<!--          ></v-select>-->
<!--        </v-col>-->
        <v-col>
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="datesRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="datesDisplayRange"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="datesRange"
                range
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(datesRange)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
              outlined
          >
            <v-card-title style="text-align: center;white-space: nowrap;">GGR</v-card-title>
            <v-card-text style="text-align: center; font-size: 2rem">{{ ngr }}</v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
              outlined
          >
            <v-card-title style="text-align: center;white-space: nowrap;">All bets</v-card-title>
            <v-card-text style="text-align: center; font-size: 2rem">{{ betsSum }}</v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
              outlined
          >
            <v-card-title style="text-align: center;white-space: nowrap;">All wins</v-card-title>
            <v-card-text style="text-align: center; font-size: 2rem">{{ winsSum }}</v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
              outlined
          >
            <v-card-title style="text-align: center;white-space: nowrap;">Games played</v-card-title>
            <v-card-text style="text-align: center; font-size: 2rem">{{ gamesPlayed }}</v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
              outlined
          >
            <v-card-title style="text-align: center;white-space: nowrap;">Players amount</v-card-title>
            <v-card-text style="text-align: center; font-size: 2rem">{{ playersCount }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="text-align: left">
            Parameter
          </th>
          <th class="text-left" style="text-align: left; padding-left: 1.7rem">
            Details
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Your operator name</td>
          <td>
            <v-text-field
                v-bind:value="profile.operator_name || ''"
                solo flat readonly hide-details
                @focus="$event.target.select();"
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <td>Your operatorId</td>
          <td>
            <v-text-field
                v-bind:value="profile.operator_uid || ''"
                solo flat readonly hide-details
                @focus="$event.target.select();"
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <td>Your callback</td>
          <td>
            <v-text-field
                v-bind:value="profile.operator_callback_url || ''"
                solo flat readonly hide-details
                @focus="$event.target.select();"
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <td>Your secret key</td>
          <td>
            <v-text-field
                v-bind:value="profile.sign_key || ''"
                solo flat readonly hide-details
                @focus="$event.target.select();"
            ></v-text-field>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-container style="display: flex; padding-bottom: 0; padding-top: 2rem">
      <v-btn
          color="#ff3b30"
          elevation="2"
          v-on:click="logOut"
          class="white--text"
          style="margin-left: auto"
      >Log Out</v-btn>
    </v-container>
  </v-container>
</template>

<script>
import * as axios from "axios";

export default {
  name: 'MainDetails',
  data() {
    return {
      currencies: ["USD", "INR", "RUB"],
      selectedCurrency: "USD",
      games: ["all"],
      selectedGame: "all",
      ngr: "", betsSum: "", winsSum: "", gamesPlayed: "", playersCount: "",
      games_to_stat: {},
      datesRange: [],
      menu: false,
      profile: {},
      playersCountAll: 0,
      gameTypes: ['all', 'signals', 'organic'],
      selectedGameType: 'all'
    };
  },
  mounted () {
    const dateNow = (new Date()).toISOString().split("T")[0];
    this.datesRange = [dateNow, dateNow];

    const ctx = this;

    axios.interceptors.response.use(function (response) {
      if (response && response.data && response.data.error) {
        ctx.logOut();
      }

      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      ctx.logOut();
      return Promise.reject(error);
    });

    // this.loadOverallStats();
    this.loadProfile();

    this.loadCurrencies();
  },
  watch:{
    selectedCurrency: function () {
      this.loadOverallStats();
    },
    selectedGame: function () {
      this.loadStatForCurrency();
    },
    datesDisplayRange: function () {
      this.loadCurrencies();
      this.loadOverallStats();
    },
    selectedGameType: function () {
      this.loadOverallStats();
    },
  },
  computed: {
    datesDisplayRange() {
      return this.datesRange.join(' ~ ');
    },
    datesRequestRange() {
      const startingAtString = this.datesRange[0];
      const startingAtDate = new Date(startingAtString);

      const endingAtString = this.datesRange[1];
      const endingAtDate = new Date((new Date(endingAtString)).getTime() + 1 * 24 * 60 * 60 * 1000);
      return [startingAtDate.toISOString(), endingAtDate.toISOString()];
    },
  },
  methods: {
    loadCurrencies: async function () {
      const res = await axios.get(
          process.env.VUE_APP_API_URL + "/getCurrencies",
          {
            params: { startingAt: this.datesRequestRange[0], endingAt: this.datesRequestRange[1], gameType: this.selectedGameType, currency: this.selectedCurrency },
            headers: {
              "authToken": localStorage.getItem("authToken")
            }
          }
      );

      this.currencies = res.data;
    },
    loadOverallStats: async function () {
      const res = await axios.get(
          process.env.VUE_APP_API_URL + "/getOverallStats",
          {
            params: { startingAt: this.datesRequestRange[0], endingAt: this.datesRequestRange[1], gameType: this.selectedGameType, currency: this.selectedCurrency },
            headers: {
              "authToken": localStorage.getItem("authToken")
            }
          }
      );

      let games = new Set(["all"]);

      this.playersCountAll = res.data.players_count;

      this.games_to_stat = res.data.games_to_stat;

      for (let game_name in this.games_to_stat){
        games.add(game_name);
      }

      this.games = Array.from(games);

      await this.loadStatForCurrency();
    },
    loadProfile: async function () {
      const res = await axios.get(
          process.env.VUE_APP_API_URL + "/getProfile",
          {
            headers: {
              "authToken": localStorage.getItem("authToken")
            }
          }
      );

      this.profile = res.data;
    },
    loadStatForCurrency: async function () {
      let betsSum = 0.0;
      let winsSum = 0.0;
      let gamesPlayed = 0;
      let usersPlayed = 0;

      for (let game_name in this.games_to_stat){
        if (this.selectedGame !== "all" && game_name !== this.selectedGame)
          continue;

        const game_currencies_to_stat = this.games_to_stat[game_name];

        const game_currency_stat = game_currencies_to_stat[this.selectedCurrency];

        try{
          betsSum += game_currency_stat.bets_sum;
          winsSum += game_currency_stat.results_sum;
          gamesPlayed += game_currency_stat.games_played;
          usersPlayed += game_currency_stat.users_played;
        }catch (e) {
          continue;
        }
      }

      this.betsSum = new Intl.NumberFormat(undefined, {maximumFractionDigits: 8}).format(betsSum);
      this.winsSum = new Intl.NumberFormat(undefined, {maximumFractionDigits: 8}).format(winsSum);
      this.gamesPlayed = new Intl.NumberFormat().format(gamesPlayed);

      this.playersCount = new Intl.NumberFormat().format(this.selectedGame === "all" ? this.playersCountAll : usersPlayed);

      this.ngr = new Intl.NumberFormat(undefined, {maximumFractionDigits: 8}).format(betsSum - winsSum);
    },
    logOut: function () {
      localStorage.removeItem("authToken");
      this.$router.push("/signin");
    }
  }
}
</script>

<style>
.v-card {
  padding-bottom: 1rem;
}
</style>
