<template>
  <v-app>
    <v-container height="350px">
      <v-card
        flat
        outlined
        style="overflow: hidden"
      >
        <v-tabs
            fixed-tabs
            show-arrows
        >
          <v-tab v-on:click="beforeTab = tab; tab = tabs.Details">
            Details
          </v-tab>
          <v-tab v-on:click="beforeTab = tab; tab = tabs.Analytics">
            Analytics
          </v-tab>
          <v-tab v-on:click="beforeTab = tab; tab = tabs.TopPlayers">
            Top players
          </v-tab>
            <v-tab v-on:click="beforeTab = tab; tab = tabs.Limits">
                Limits
            </v-tab>
        </v-tabs>
        <v-container>
          <transition v-bind:name="'slide-left'" mode="out-in">
            <MainDetails v-show="tab === tabs.Details"></MainDetails>
          </transition>
          <transition v-bind:name="slide()" mode="out-in">
            <MainAnalytics v-show="tab === tabs.Analytics"></MainAnalytics>
          </transition>
          <transition v-bind:name="slide()" mode="out-in">
            <MainTopPlayers v-show="tab === tabs.TopPlayers"></MainTopPlayers>
          </transition>
            <transition v-bind:name="slide()" mode="out-in">
                <MainLimits v-show="tab === tabs.Limits"></MainLimits>
            </transition>
        </v-container>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import MainDetails from "@/components/Main/MainDetails"
import MainTopPlayers from "@/components/Main/MainTopPlayers"
import MainAnalytics from "@/components/Main/MainAnalytics"
import MainLimits from "@/components/Main/MainLimits.vue";

export default {
  name: 'Main',
  components: {MainDetails, MainTopPlayers, MainAnalytics, MainLimits},
  data() {
    return {
      tabs: {
        Details: 0,
        Analytics: 1,
        TopPlayers: 2,
        Limits: 3,
      },
      tab: 0,
      beforeTab: 0
    }
  },
  mounted() {
    this.tab = this.tabs.Details;
  },
  methods: {
    slide: function() {
      return this.tab < this.beforeTab ? 'slide-left' : 'slide-right'
    },
  }
}
</script>
