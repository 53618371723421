import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import babelPolyfill from 'babel-polyfill'
import router from './router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

import VueHighcharts from 'vue2-highcharts'

Vue.component('VueHighcharts', VueHighcharts)

Vue.config.productionTip = false

// Vue.config.productionTip = false; // Check what it does

new Vue({
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: "#34c759",
          secondary: "#30d158",
          accent: "#5856d6",
        },
      }
    }
  }),

  router,

  el: '#app',
})
