<template>
  <v-app>
    <v-form style="min-height: 100vh; height: fit-content; display: flex">
      <div class="signin-box-wrapper">
        <div class="signin-box">
          <div class="logo-text">InOut</div>
          <div class="title">Sign In</div>
          <div class="input-wrapper">
            <template>
              <v-text-field
                  outlined dense
                  class="input"
                  :rules="ruleSet1" v-model="operatorId" v-bind:id="operatorId" type="text" label="operatorId"></v-text-field>
            </template>
          </div>
          <div class="input-wrapper">
            <template>
              <v-text-field
                  outlined dense
                  class="input"
                  :rules="ruleSet2" v-model="password" v-bind:id="password" type="password" label="Password"></v-text-field>
            </template>
          </div>
          <div class="button-wrapper">
            <v-btn
                color="primary"
                elevation="2"
                v-on:click="signIn"
                class="white--text"
                style="margin-left: auto;"
            >Sign In</v-btn>
          </div>
        </div>
      </div>
    </v-form>
  </v-app>
</template>

<script>
import * as axios from 'axios';
export default {
  name: 'SignIn',
  methods: {
    signIn: async function () {
      const context = this;
      if (!context.operatorId)
        return;
      if (!context.password)
        return;

      const res = await axios.post(
          process.env.VUE_APP_API_URL + "/login",
          {
            "operatorId": context.operatorId,
            "password": context.password
          }
      );

      if (!res.data.success)
        return;
      localStorage.setItem("authToken", res.data.authToken);

      await context.$router.push("/");
    }
  },
  data: () => ({
    operatorId: "",
    password: "",
    ruleSet1: [
      value => !!value || 'Required.',
    ],
    ruleSet2: [
      value => !!value || 'Required.',
    ],
  }),
}
</script>

<style scoped>
.signin-box-wrapper{
  margin: auto;
  height: 100%;
  max-height: 100vh;
  display: flex;
}

.signin-box{
  display: flex;
  flex-direction: column;
  margin: auto;
  border: rgba(0, 0, 0, 0.1) solid .1rem;
  border-radius: .5rem;
  padding: 2rem 2.5rem;
}

.signin-box .title {
  margin: 1.5rem 0;
  font-size: 2rem !important;
}

.input-wrapper {
  text-align: right;
  margin-bottom: 1rem;
}

.input-wrapper label {
  margin-right: 1rem;
}

.button-wrapper {
  width: 100%;
  display: flex;
}

.button-wrapper button {
}

.input {
  width: 20rem;
}

.logo-text {
  width: 10rem;
  margin: auto;
  font-size: 3rem;
  text-align: center;
}

.forgotPassword {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  width: fit-content;
  margin: 1.2rem auto 0;
}

.forgotPassword:hover {
  color: rgba(0, 0, 0, 1);
}
</style>
