<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select
            :items="currencies"
            v-model="selectedCurrency"
            label="Currency"
        ></v-select>
      </v-col>
      <v-col>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="datesRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="datesDisplayRange"
                label="Date range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="datesRange"
              range
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(datesRange)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="chart">
        <vue-highcharts :options="betsChart" ref="betsChart"></vue-highcharts>
      </v-col>
      <v-col class="chart">
        <vue-highcharts :options="gamesChart" ref="gamesChart"></vue-highcharts>
      </v-col>
      <v-col class="chart">
        <vue-highcharts :options="playersChart" ref="playersChart"></vue-highcharts>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueHighcharts from 'vue2-highcharts'
import * as axios from "axios";

export default {
  name: 'MainAnalytics',
  components: {VueHighcharts},
  data() {
    return {
      currencies: ["USD", "RUB", "INR"],
      selectedCurrency: "USD",
      dates: [],
      datesRange: [],
      betsSumPerDate: [], winsSumPerDate: [], gamesPlayedPerDate: [], playersCountPerDate: [],
      betsChart: {
        chart: {
          type: "line",
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Users' indicators"
        },
        series: [
          {
            name: "Bets in that day",
            data: []
          },
          {
            name: "Wins in that day",
            data: []
          }
        ]
      },
      gamesChart: {
        chart: {
          type: "line"
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Users' games"
        },
        series: [
          {
            name: "games in that day",
            data: []
          }
        ]
      },
      playersChart: {
        chart: {
          type: "line"
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Users' played"
        },
        series: [
          {
            name: "Users in that day",
            data: []
          }
        ]
      }
    };
  },
  computed: {
    datesDisplayRange() {
      return this.datesRange.join(' ~ ');
    },
    datesRequestRange() {
      const startingAtString = this.datesRange[0];
      const startingAtDate = new Date(startingAtString);

      const endingAtString = this.datesRange[1];
      const endingAtDate = new Date();
      endingAtDate.setDate((new Date(endingAtString)).getDate() + 1);
      return [startingAtDate.toISOString(), endingAtDate.toISOString()];
    },
  },
  mounted () {
    const tempDate = new Date();
    tempDate.setDate((new Date()).getDate() - 7);
    const dateWeekAgo = tempDate.toISOString().split("T")[0];
    const dateNow = (new Date()).toISOString().split("T")[0];
    this.datesRange = [dateWeekAgo, dateNow];

    this.loadCurrencies();

    // this.loadTimeSeriesStats();
  },
  watch:{
    selectedCurrency: function () {
      this.loadTimeSeriesStats();
    },
    datesDisplayRange: function () {
      this.loadCurrencies();
      this.loadTimeSeriesStats();
    },
  },
  methods: {
    loadCurrencies: async function () {
      const res = await axios.get(
          process.env.VUE_APP_API_URL + "/getCurrencies",
          {
            params: { startingAt: this.datesRequestRange[0], endingAt: this.datesRequestRange[1], gameType: this.selectedGameType, currency: this.selectedCurrency },
            headers: {
              "authToken": localStorage.getItem("authToken")
            }
          }
      );

      this.currencies = res.data;
    },
    loadTimeSeriesStats: async function () {
      const res = await axios.get(
          process.env.VUE_APP_API_URL + "/getTimeSeries",
          {
            params: { startingAt: this.datesRequestRange[0], endingAt: this.datesRequestRange[1], currency: this.selectedCurrency },
            headers: {
              "authToken": localStorage.getItem("authToken")
            }
          }
      );

      let dates = [];

      this.dates_to_stat = res.data;

      for (let date_name in this.dates_to_stat){
        dates.push(date_name);
      }

      this.dates = dates;

      await this.loadStatForCurrency();
    },
    loadStatForCurrency: async function () {
      let betsSumPerDate = [];
      let winsSumPerDate = [];
      let gamesPlayedPerDate = [];
      let usersPlayedPerDate = [];

      for (let date_name in this.dates_to_stat){
        const date_currencies_to_stat = this.dates_to_stat[date_name];

        const date_currency_stat = date_currencies_to_stat[this.selectedCurrency];

        if (date_currency_stat){
          betsSumPerDate.push([date_name, date_currency_stat.bets_sum]);
          winsSumPerDate.push([date_name, date_currency_stat.results_sum]);
          gamesPlayedPerDate.push([date_name, date_currency_stat.games_played]);
          usersPlayedPerDate.push([date_name, date_currency_stat.users_played]);
        }
      }

      this.betsSumPerDate = betsSumPerDate;
      this.winsSumPerDate = winsSumPerDate;
      this.gamesPlayedPerDate = gamesPlayedPerDate;
      this.playersCountPerDate = usersPlayedPerDate;

      this.$refs.betsChart.getChart().series[0].setData(this.betsSumPerDate);
      this.$refs.betsChart.getChart().series[1].setData(this.winsSumPerDate);
      this.$refs.gamesChart.getChart().series[0].setData(this.gamesPlayedPerDate);
      this.$refs.playersChart.getChart().series[0].setData(this.playersCountPerDate);
    }
  }
}
</script>

<style scoped>
.chart {
  min-width: 15rem;
}
</style>
