<template>
  <v-container>
    <v-container>
        <v-row>
            <v-col>
                <v-select
                        :items="limitMode"
                        v-model="selectedLimitMode"
                        label="Limit mode"
                ></v-select>
            </v-col>
        </v-row>
      <v-row v-if="selectedLimitMode === 'gameMode'">
        <v-col>
          <v-select
              :items="gameModesList"
              v-model="selectedGameMode"
              label="GameMode"
          ></v-select>
        </v-col>
      </v-row>
        <v-row v-if="selectedLimitMode === 'player'">
            <v-col>
                <v-text-field
                        v-model="userId"
                        label="userId"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-btn v-on:click="loadLimits">
                    Find
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="currency"
                    label="currency"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                        v-model="minBetAmount"
                        label="minBetAmount"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                        v-model="maxBetAmount"
                        label="maxBetAmount"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                        v-model="maxWinAmount"
                        label="maxWinAmount"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-btn v-on:click="updateLimits">
                    UPDATE
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="text-align: left">
            currency
          </th>
          <th class="text-left" style="text-align: left">
            minBetAmount
          </th>
          <th class="text-left" style="text-align: left; padding-left: 1.7rem">
              maxBetAmount
          </th>
          <th class="text-left" style="text-align: left; padding-left: 1.7rem">
            maxWinAmount
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in limits" :key="item.currency" v-on:click="setConfig(item)" style="cursor: pointer">
          <td>{{ item.currency }}</td>
          <td>{{ item.minBetAmount }}</td>
          <td>{{ item.maxBetAmount }}</td>
          <td>{{ item.maxWinAmount }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import * as axios from "axios";

export default {
  name: 'MainLimits',
  data() {
    return {
      selectedLimitMode: "operator",
      limitMode: ["operator", "gameMode", "player"],
      selectedGameMode: "all",
      gameModesList: [],
      userId: '',

      limits: [],

        currency: '',
        minBetAmount: '',
        maxBetAmount: '',
        maxWinAmount: '',
    };
  },
  mounted () {
    this.loadGameModes();
    this.loadLimits();
  },
    watch:{
        selectedLimitMode: function () {
            if (this.selectedLimitMode === 'operator') this.loadLimits();
        },
        selectedGameMode: function () {
            this.loadLimits();
        },
    },
  methods: {
      loadGameModes: async function () {
      const res = await axios.get(
          process.env.VUE_APP_API_URL + "/getGameModes",
          {
            headers: {
              "authToken": localStorage.getItem("authToken")
            }
          }
      );

      this.gameModesList = res.data;
    },
      loadLimits: async function () {
          const params = {};
          if (this.selectedLimitMode === 'gameMode' && this.selectedGameMode !== 'all')
              params.gameMode = this.selectedGameMode;

          if (this.selectedLimitMode === 'player')
              params.userId = this.userId;

          const res = await axios.get(
              process.env.VUE_APP_API_URL + "/getLimits",
              {
                  params,
                  headers: {
                      "authToken": localStorage.getItem("authToken")
                  }
              }
          );

          this.limits = res.data;
      },
      setConfig: async function (item) {
          this.currency = item.currency;
          this.minBetAmount = item.minBetAmount;
          this.maxBetAmount = item.maxBetAmount;
          this.maxWinAmount = item.maxWinAmount;

          window.scroll({
              top: 0,
              behavior: 'smooth',
          })
      },
      updateLimits: async function () {
          const params = {
              currency: this.currency,
              minBetAmount: this.minBetAmount,
              maxBetAmount: this.maxBetAmount,
              maxWinAmount: this.maxWinAmount,
          };

          if (this.selectedLimitMode === 'gameMode' && this.selectedGameMode !== 'all')
              params.gameMode = this.selectedGameMode;

          if (this.selectedLimitMode === 'player')
              params.userId = this.userId;

          await axios.get(
              process.env.VUE_APP_API_URL + "/setLimits",
              {
                  params,
                  headers: {
                      "authToken": localStorage.getItem("authToken")
                  }
              }
          );

          await this.loadLimits();
      },
  }
}
</script>
